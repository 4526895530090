// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aboutaia-js": () => import("./../../../src/pages/aboutaia.js" /* webpackChunkName: "component---src-pages-aboutaia-js" */),
  "component---src-pages-admin-runs-add-js": () => import("./../../../src/pages/admin/runs/add.js" /* webpackChunkName: "component---src-pages-admin-runs-add-js" */),
  "component---src-pages-admin-runs-edit-js": () => import("./../../../src/pages/admin/runs/edit.js" /* webpackChunkName: "component---src-pages-admin-runs-edit-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-autodesk-js": () => import("./../../../src/pages/courses-autodesk.js" /* webpackChunkName: "component---src-pages-courses-autodesk-js" */),
  "component---src-pages-courses-aws-js": () => import("./../../../src/pages/courses-aws.js" /* webpackChunkName: "component---src-pages-courses-aws-js" */),
  "component---src-pages-courses-bentley-js": () => import("./../../../src/pages/courses-bentley.js" /* webpackChunkName: "component---src-pages-courses-bentley-js" */),
  "component---src-pages-courses-bim-js": () => import("./../../../src/pages/courses-bim.js" /* webpackChunkName: "component---src-pages-courses-bim-js" */),
  "component---src-pages-courses-ifc-js": () => import("./../../../src/pages/courses-ifc.js" /* webpackChunkName: "component---src-pages-courses-ifc-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-courses-skillsfuture-js": () => import("./../../../src/pages/courses-skillsfuture.js" /* webpackChunkName: "component---src-pages-courses-skillsfuture-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/FAQ.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forlearners-js": () => import("./../../../src/pages/forlearners.js" /* webpackChunkName: "component---src-pages-forlearners-js" */),
  "component---src-pages-funding-js": () => import("./../../../src/pages/funding.js" /* webpackChunkName: "component---src-pages-funding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-forms-js": () => import("./../../../src/pages/internal/forms.js" /* webpackChunkName: "component---src-pages-internal-forms-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-psea-js": () => import("./../../../src/pages/PSEA.js" /* webpackChunkName: "component---src-pages-psea-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-reschedule-js": () => import("./../../../src/pages/reschedule.js" /* webpackChunkName: "component---src-pages-reschedule-js" */),
  "component---src-pages-sfc-js": () => import("./../../../src/pages/SFC.js" /* webpackChunkName: "component---src-pages-sfc-js" */),
  "component---src-pages-short-courses-js": () => import("./../../../src/pages/short-courses.js" /* webpackChunkName: "component---src-pages-short-courses-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-theteam-js": () => import("./../../../src/pages/theteam.js" /* webpackChunkName: "component---src-pages-theteam-js" */),
  "component---src-pages-user-runs-assessments-js": () => import("./../../../src/pages/user/runs/assessments.js" /* webpackChunkName: "component---src-pages-user-runs-assessments-js" */),
  "component---src-pages-user-runs-attendance-js": () => import("./../../../src/pages/user/runs/attendance.js" /* webpackChunkName: "component---src-pages-user-runs-attendance-js" */),
  "component---src-pages-user-runs-enrolments-js": () => import("./../../../src/pages/user/runs/enrolments.js" /* webpackChunkName: "component---src-pages-user-runs-enrolments-js" */),
  "component---src-pages-whyaia-js": () => import("./../../../src/pages/whyaia.js" /* webpackChunkName: "component---src-pages-whyaia-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-certification-post-js": () => import("./../../../src/templates/certification-post.js" /* webpackChunkName: "component---src-templates-certification-post-js" */),
  "component---src-templates-course-post-js": () => import("./../../../src/templates/course-post.js" /* webpackChunkName: "component---src-templates-course-post-js" */)
}

